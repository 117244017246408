// API token for nft.storage. Please sign up and replace this with your own key from https://nft.storage/manage/
export const NFT_STORAGE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDQxMjY5RGIwQjdjNzA3Y0I0MjZCMjg2MUI1NTY2ZEEwZTZjQzVmOTAiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTYyMjA2NTE1OTYxMCwibmFtZSI6Im5mdHNjaG9vbC10ZXN0In0.DSgQ5yot5Qyp4_OMg16h7hHOSRUQK7jkjiH7sM3DHOE";

// Infura project ID. If you're building a real app based on this, please replace with your own from https://infura.io/dashboard/ethereum
export const INFURA_ID = "69f75bb89ba74822b512b464ef1495c9";

// Key for Etherscan.io. If you're building a real app based on this, please replace with your own from https://etherscan.io/myapikey
export const ETHERSCAN_KEY = "6EAGC2DEG6PP3M1GP1I86QJPQAUE4ESW1W";

// BLOCKNATIVE ID FOR Notify.js:
export const BLOCKNATIVE_DAPPID = "0b58206a-f3c0-4701-a62f-73c7243e8c77";

// Max number of NFTs to be minted
export const MAX_MINTABLE_NFT = 159 ;//143;
// Max number of NFTs to be minted - Witches Oracle
export const MAX_MINTABLE_NFT_WITCHES = 168;
// Max number of NFTs to be minted - Banner Oracle
export const MAX_MINTABLE_NFT_BANNER = 333;
// Max number of NFTs to be minted - Haunted Witches
export const MAX_MINTABLE_NFT_FTM_HAUNTED = 630;
export const MAX_MINTABLE_NFT_AVAX_HAUNTED = 200;
export const MAX_MINT_PER_TX = 5;

export const DEFAULT_CONTRACT_NAME = "NatureOracleNFTMinter";
export const CONTRACT_NAME_WITCHES = "WitchesOracle";
export const CONTRACT_NAME_BANNER = "BannersOracle";
export const CONTRACT_NAME_HAUNTED = "HauntedWitches";

export const NETWORK = chainId => {
  for (const n in NETWORKS) {
    if (NETWORKS[n].chainId === chainId) {
      return NETWORKS[n];
    }
  }
};

export const NETWORKS = {
  localhost: {
    name: "localhost",
    color: "#666666",
    chainId: 31337,
    blockExplorer: "",
    rpcUrl: "http://" + window.location.hostname + ":8545",
  },
  mainnet: {
    name: "Ethereum Mainnet",
    color: "#ff8b9e",
    chainId: 1,
    rpcUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`,
    blockExplorer: "https://etherscan.io/",
  },
  kovan: {
    name: "kovan",
    color: "#7003DD",
    chainId: 42,
    rpcUrl: `https://kovan.infura.io/v3/${INFURA_ID}`,
    blockExplorer: "https://kovan.etherscan.io/",
    faucet: "https://gitter.im/kovan-testnet/faucet", // https://faucet.kovan.network/
  },
  rinkeby: {
    name: "rinkeby",
    color: "#e0d068",
    chainId: 4,
    rpcUrl: `https://rinkeby.infura.io/v3/${INFURA_ID}`,
    faucet: "https://faucet.rinkeby.io/",
    blockExplorer: "https://rinkeby.etherscan.io/",
  },
  ropsten: {
    name: "ropsten",
    color: "#F60D09",
    chainId: 3,
    faucet: "https://faucet.ropsten.be/",
    blockExplorer: "https://ropsten.etherscan.io/",
    rpcUrl: `https://ropsten.infura.io/v3/${INFURA_ID}`,
  },
  goerli: {
    name: "goerli",
    color: "#0975F6",
    chainId: 5,
    faucet: "https://goerli-faucet.slock.it/",
    blockExplorer: "https://goerli.etherscan.io/",
    rpcUrl: `https://goerli.infura.io/v3/${INFURA_ID}`,
  },
  xdai: {
    name: "xdai",
    color: "#48a9a6",
    chainId: 100,
    price: 1,
    gasPrice: 1000000000,
    rpcUrl: "https://dai.poa.network",
    faucet: "https://xdai-faucet.top/",
    blockExplorer: "https://blockscout.com/poa/xdai/",
  },
  matic: {
    name: "matic",
    color: "#2bbdf7",
    chainId: 137,
    price: 1,
    gasPrice: 1000000000,
    rpcUrl: "https://rpc-mainnet.maticvigil.com",
    faucet: "https://faucet.matic.network/",
    blockExplorer: "https://explorer-mainnet.maticvigil.com//",
  },
  mumbai: {
    name: "mumbai",
    color: "#92D9FA",
    chainId: 80001,
    price: 1,
    gasPrice: 1000000000,
    rpcUrl: "https://rpc-mumbai.maticvigil.com",
    faucet: "https://faucet.matic.network/",
    blockExplorer: "https://mumbai-explorer.matic.today/",
  },
  ftmtest: {
    name: "ftmtest",
    color: "#2bbdf7",
    chainId: 4002,
    gasPrice: 180000000000,
    faucet: "https://faucet.fantom.network/",
    blockExplorer: "https://testnet.ftmscan.com/",
    rpcUrl: "https://rpc.testnet.fantom.network",
  },
  opera: {
    name: "Fantom Opera",
    color: "#92D9FA",
    chainId: 250,
    gasPrice: 800000000000,
    blockExplorer: "https://ftmscan.com/",
    rpcUrl: "https://rpc.ftm.tools/",
  },
  fujiAvalanche: {
    name: "fujiAvalanche",
    color: "#2bbdf7",
    chainId: 43113,
    gasPrice: 280000000000,
    faucet: "https://faucet.avax.network/",
    blockExplorer: "https://testnet.snowtrace.io//",
    rpcUrl: "https://api.avax-test.network/ext/bc/C/rpc",
  },
  mainnetAvalanche: {
    name: "Avalanche",
    color: "#92D9FA",
    chainId: 43114,
    gasPrice: 280000000000,
    blockExplorer: "https://snowtrace.io/",
    rpcUrl: "https://api.avax.network/ext/bc/C/rpc",
  },
};