import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import ScrollElement from 'rc-scroll-anim/lib/ScrollElement';
import TweenOne from 'rc-tween-one';
import { DownOutlined } from '@ant-design/icons';
import { Statistic, Image, Tooltip, Carousel } from 'antd';
import { Row, Col} from 'antd';
import ConnectWallet from "./ConnectWallet.jsx";
import HauntedWitchesMintButton from "./HauntedWitchesMintButton.jsx";

import title_surprise from "./img/SVG/title_bitwitches_bleed.svg";
//import title_surprise from "./img/SVG/title_bitwitches_swamp.svg";
import Citation from "./Citation.jsx";

// DEBUG FLAG - set to false in prod
const DEBUG = false;

const { Countdown } = Statistic;

// BANNERS ORACLE LAUNCH
// UNCOMMENT FOR PROD
const MINT_START = new Date(Date.UTC(2022, 7, 11, 21, 10, 0, 0));         // Aug 11 - 4:00PM CST 9:00PM UTC
const MINT_PUBLIC_START = new Date(Date.UTC(2022, 7, 11, 21, 30, 0, 0)); // Aug 11 - 4:30PM CST 9:30PM UTC

// TEST CODE
// const MINT_START = new Date(Date.UTC(2022, 6, 29, 2, 57, 0, 0));       // Aug 11 - 2:30PM CST 7:30PM UTC
// const MINT_PUBLIC_START = new Date(Date.UTC(2022, 7, 11, 21, 30, 0, 0)); // Aug 11 - 4:30PM CST 9:30PM UTC

const calculateTimeLeft = () => {
  let difference = MINT_PUBLIC_START - +new Date();

  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      in: "The public sale will open ",
      day: Math.floor(difference / (1000 * 60 * 60 * 24)),
      h: Math.floor((difference / (1000 * 60 * 60)) % 24),
      min: Math.floor((difference / 1000 / 60) % 60),
      s: Math.floor((difference / 1000) % 60)
    };
  }

  return timeLeft;
}

export default function FreeBannerMint({ 
  gasPrice,
  signer,
  provider,
  address,
  blockExplorer,
  totalSupply,
  price,
  presaleprice,
  balance,
  web3Modal,
  loadWeb3Modal,
  logoutOfWeb3Modal,
  whiteListEnabled,
  }) {

  // WITCHES ORACLE LAUNCH
  // UNCOMMENT FOR PROD
  const [isCountdownFinished, setIsCountdownFinished] = useState(new Date() >= MINT_START);
  //const [isCountdownFinished, setIsCountdownFinished] = useState(true);
  const [isPublicSaleEnabled, setIsPublicSaleEnabled] = useState(new Date() >= MINT_PUBLIC_START);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const mintRemaining = false;

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsCountdownFinished(new Date() >= MINT_START);
      setIsPublicSaleEnabled(new Date() >= MINT_PUBLIC_START);
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });  

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={interval}>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  if (DEBUG) console.log("provider", provider.connection.url);
  if (DEBUG) console.log("address", address);
  if (DEBUG) console.log("countdown status", isCountdownFinished);
  
  const countdown = (
    <Countdown title="COUNTDOWN TO MINT" value={MINT_START} format="D d HH:mm:ss"/>
  );

  const countdownPublicSale = (
    <p>
    {timerComponents}
    </p>
  );

  const outOfNFTs = (
    <p className="mint-outofnft ant-statistic-content-value">
        Sold out!
    </p>
  );

  return (
    <section id="nft-readings">
        <div className="container">
            <img className="nft-reading-title" src={title_surprise}/>
            <div>
                {/* <Citation 
                citation={"Ask and it will be given to you \n \
                Seek and you will find \n \
                Knock and the door will be opened to you \n \
                "}
                author="Matthew 7:7"
                /> */}
                <Row>
                  <Col>
                  <p key="content" className="content-witches">A collection of pixel-art NFT PFP on Fantom/Avax by Zoey</p>
                  <div>                  
                    <Tooltip title={"Your coven on Fantom"} color={'#1969ff'} key={'icon_fantom'} placement="bottom">
                    <a href="https://natureoracle.com/haunted"><Image className="paintswap-icon" height={"50px"} src="img/fantom_round.svg" preview={false}/></a>
                    </Tooltip>
                    <Tooltip title={"Your coven on Avax"} color={'#e84142'} key={'icon_avax'} placement="bottom">
                      <Image className="paintswap-icon highlight" height={"50px"} src="img/avax_square_red.svg" preview={false}/>
                    </Tooltip>
                  </div>
                  <p key="content" className="content-witches-italic">Traversing to FTM costs ~0.2 AVAX per NFT.</p>
                  <p key="content" className="content-witches-italic">Any unused fees by LayerZero will be refunded</p>
                  <Carousel autoplay>
                    <div>
                      <Image className="nftgallery-bitwitches-img" src="img/bitwitches_1.png" preview={false}/> 
                    </div>
                    <div>
                      <Image className="nftgallery-bitwitches-img" src="img/bitwitches_2.png" preview={false}/> 
                    </div>
                    <div>
                      <Image className="nftgallery-bitwitches-img" src="img/bitwitches_3.png" preview={false}/> 
                    </div>
                    <div>
                      <Image className="nftgallery-bitwitches-img" src="img/bitwitches_4.png" preview={false}/> 
                    </div>
                  </Carousel>
                  </Col>
                </Row>
                <Row>
                    {/* <Col md={5} sm={0} xs={0}>
                      <Image className="nftgallery-img" src={slider} preview={false}/>
                    </Col> */}
                    <Col md={24} sm={24} xs={24}>
                    {/* <p key="content" className="content-witches timeline-text">Are you ready to meet them all?</p>*/}
                    {!isCountdownFinished && countdown}
                    {isCountdownFinished && !isPublicSaleEnabled && timerComponents.length && countdownPublicSale}
                    {/* <Tooltip title={"OperaHouse"} color={'#de767b'} key={'#de767b'} placement="bottom">
                      <a href="https://operahouse.online/collection/WitchesOracle" target="_blank"><Image className="paintswap-icon" width={"70px"} src={"img/oh.png"} preview={false}/></a>
                    </Tooltip>
                    <Tooltip title={"PaintSwap"} color={'#de767b'} key={'#de767c'} placement="bottom">
                    <a href="https://paintswap.finance/marketplace/collections/0x0fb9d7cc9a3fbfc62a71182552a576859ae0c758" target="_blank"><Image className="paintswap-icon" width={"70px"} src={"img/paintswap_icon.png"} preview={false}/></a>
                    </Tooltip> */}
                    {isCountdownFinished && provider.connection.url === 'unknown:' && mintRemaining && 
                      <ConnectWallet
                        address={address}
                        web3Modal={web3Modal}
                        loadWeb3Modal={loadWeb3Modal}
                        logoutOfWeb3Modal={logoutOfWeb3Modal}
                      />  
                    } 
                    {isCountdownFinished && mintRemaining && (provider.connection.url !== 'unknown:') && 
                      <HauntedWitchesMintButton
                        signer={signer}
                        provider={provider}
                        address={address}
                        blockExplorer={blockExplorer}
                        totalSupply={totalSupply}
                        price={price}
                        balance={balance}
                        whiteListEnabled={whiteListEnabled}
                        publicSaleEnabled={isPublicSaleEnabled}
                      />
                    } 
                    </Col>
                    {/* <Col md={5} sm={0} xs={0}>
                    <Image className="nftgallery-img" src={slider_witches} preview={false}/>
                    </Col> */}
                  </Row>
                  <Row>
                    <Col md={24} sm={24} xs={24}>
                      <p>&nbsp;</p>
                      {isCountdownFinished && 
                        <p key="content" className="content-witches timeline-text">Find all minted Witches on <a href="https://nftkey.app/collections/bitwitcheshauntedavax/" target="_blank">NFTKEY</a></p>
                      }
                      <Tooltip title={"Trade BitWitches:Haunted on NFTKEY"} color={'#de767b'} key={'#de767d1'} placement="bottom">
                      {isCountdownFinished && 
                        <a href="https://nftkey.app/collections/bitwitcheshauntedavax/" target="_blank"><Image className="paintswap-icon" height={"70px"} src={"img/nftkey.svg"} preview={false}/></a>
                      }
                      </Tooltip>                    
                    </Col>
                  </Row>
                </div>
        </div>
    </section>
  );
}